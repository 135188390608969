<!--
 * @Author: ZXP
 * @LastEditors: ZXP
 * @Date: 2023-03-17 10:26:18
 * @LastEditTime: 2023-03-17 13:09:40
 * @Email: 895357083@qq.com
 * @FilePath: /media-demo-2/src/views/HomeView.vue
 * @Environment: Win 10
 * @Description: 
-->
<template>
  <video class="vedio-wrap" ref="input_video"></video>
  <div class="canvas-wrap">
    <canvas
      class="output_canvas"
      ref="output_canvas"
      :width="mediaWidth"
      :height="mediaHeight"
    ></canvas>
  </div>
  <div id="container"></div>
  <div id="menu">
    <button
      id="table"
      @click="transform(targets.table, 2000)"
      :class="{ choose: transformation == 'table' }"
    >
      table
    </button>
    <button
      id="sphere"
      @click="transform(targets.sphere, 2000)"
      :class="{ choose: transformation == 'sphere' }"
    >
      sphere
    </button>
    <button
      id="helix"
      @click="transform(targets.helix, 2000)"
      :class="{ choose: transformation == 'helix' }"
    >
      helix
    </button>
    <button
      id="grid"
      @click="transform(targets.grid, 2000)"
      :class="{ choose: transformation == 'grid' }"
    >
      grid
    </button>
  </div>
</template>

<script setup>
import { Hands, HAND_CONNECTIONS } from "@mediapipe/hands";
import { Camera } from "@mediapipe/camera_utils";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import { onMounted, computed, getCurrentInstance, ref } from "vue";

// 使用refs必须引入
const internalInstance = getCurrentInstance();
// 元素周期组件
var table = [
  "H",
  "Hydrogen",
  "1.00794",
  1,
  1,
  "He",
  "Helium",
  "4.002602",
  18,
  1,
  "Li",
  "Lithium",
  "6.941",
  1,
  2,
  "Be",
  "Beryllium",
  "9.012182",
  2,
  2,
  "B",
  "Boron",
  "10.811",
  13,
  2,
  "C",
  "Carbon",
  "12.0107",
  14,
  2,
  "N",
  "Nitrogen",
  "14.0067",
  15,
  2,
  "O",
  "Oxygen",
  "15.9994",
  16,
  2,
  "F",
  "Fluorine",
  "18.9984032",
  17,
  2,
  "Ne",
  "Neon",
  "20.1797",
  18,
  2,
  "Na",
  "Sodium",
  "22.98976...",
  1,
  3,
  "Mg",
  "Magnesium",
  "24.305",
  2,
  3,
  "Al",
  "Aluminium",
  "26.9815386",
  13,
  3,
  "Si",
  "Silicon",
  "28.0855",
  14,
  3,
  "P",
  "Phosphorus",
  "30.973762",
  15,
  3,
  "S",
  "Sulfur",
  "32.065",
  16,
  3,
  "Cl",
  "Chlorine",
  "35.453",
  17,
  3,
  "Ar",
  "Argon",
  "39.948",
  18,
  3,
  "K",
  "Potassium",
  "39.948",
  1,
  4,
  "Ca",
  "Calcium",
  "40.078",
  2,
  4,
  "Sc",
  "Scandium",
  "44.955912",
  3,
  4,
  "Ti",
  "Titanium",
  "47.867",
  4,
  4,
  "V",
  "Vanadium",
  "50.9415",
  5,
  4,
  "Cr",
  "Chromium",
  "51.9961",
  6,
  4,
  "Mn",
  "Manganese",
  "54.938045",
  7,
  4,
  "Fe",
  "Iron",
  "55.845",
  8,
  4,
  "Co",
  "Cobalt",
  "58.933195",
  9,
  4,
  "Ni",
  "Nickel",
  "58.6934",
  10,
  4,
  "Cu",
  "Copper",
  "63.546",
  11,
  4,
  "Zn",
  "Zinc",
  "65.38",
  12,
  4,
  "Ga",
  "Gallium",
  "69.723",
  13,
  4,
  "Ge",
  "Germanium",
  "72.63",
  14,
  4,
  "As",
  "Arsenic",
  "74.9216",
  15,
  4,
  "Se",
  "Selenium",
  "78.96",
  16,
  4,
  "Br",
  "Bromine",
  "79.904",
  17,
  4,
  "Kr",
  "Krypton",
  "83.798",
  18,
  4,
  "Rb",
  "Rubidium",
  "85.4678",
  1,
  5,
  "Sr",
  "Strontium",
  "87.62",
  2,
  5,
  "Y",
  "Yttrium",
  "88.90585",
  3,
  5,
  "Zr",
  "Zirconium",
  "91.224",
  4,
  5,
  "Nb",
  "Niobium",
  "92.90628",
  5,
  5,
  "Mo",
  "Molybdenum",
  "95.96",
  6,
  5,
  "Tc",
  "Technetium",
  "(98)",
  7,
  5,
  "Ru",
  "Ruthenium",
  "101.07",
  8,
  5,
  "Rh",
  "Rhodium",
  "102.9055",
  9,
  5,
  "Pd",
  "Palladium",
  "106.42",
  10,
  5,
  "Ag",
  "Silver",
  "107.8682",
  11,
  5,
  "Cd",
  "Cadmium",
  "112.411",
  12,
  5,
  "In",
  "Indium",
  "114.818",
  13,
  5,
  "Sn",
  "Tin",
  "118.71",
  14,
  5,
  "Sb",
  "Antimony",
  "121.76",
  15,
  5,
  "Te",
  "Tellurium",
  "127.6",
  16,
  5,
  "I",
  "Iodine",
  "126.90447",
  17,
  5,
  "Xe",
  "Xenon",
  "131.293",
  18,
  5,
  "Cs",
  "Caesium",
  "132.9054",
  1,
  6,
  "Ba",
  "Barium",
  "132.9054",
  2,
  6,
  "La",
  "Lanthanum",
  "138.90547",
  4,
  9,
  "Ce",
  "Cerium",
  "140.116",
  5,
  9,
  "Pr",
  "Praseodymium",
  "140.90765",
  6,
  9,
  "Nd",
  "Neodymium",
  "144.242",
  7,
  9,
  "Pm",
  "Promethium",
  "(145)",
  8,
  9,
  "Sm",
  "Samarium",
  "150.36",
  9,
  9,
  "Eu",
  "Europium",
  "151.964",
  10,
  9,
  "Gd",
  "Gadolinium",
  "157.25",
  11,
  9,
  "Tb",
  "Terbium",
  "158.92535",
  12,
  9,
  "Dy",
  "Dysprosium",
  "162.5",
  13,
  9,
  "Ho",
  "Holmium",
  "164.93032",
  14,
  9,
  "Er",
  "Erbium",
  "167.259",
  15,
  9,
  "Tm",
  "Thulium",
  "168.93421",
  16,
  9,
  "Yb",
  "Ytterbium",
  "173.054",
  17,
  9,
  "Lu",
  "Lutetium",
  "174.9668",
  18,
  9,
  "Hf",
  "Hafnium",
  "178.49",
  4,
  6,
  "Ta",
  "Tantalum",
  "180.94788",
  5,
  6,
  "W",
  "Tungsten",
  "183.84",
  6,
  6,
  "Re",
  "Rhenium",
  "186.207",
  7,
  6,
  "Os",
  "Osmium",
  "190.23",
  8,
  6,
  "Ir",
  "Iridium",
  "192.217",
  9,
  6,
  "Pt",
  "Platinum",
  "195.084",
  10,
  6,
  "Au",
  "Gold",
  "196.966569",
  11,
  6,
  "Hg",
  "Mercury",
  "200.59",
  12,
  6,
  "Tl",
  "Thallium",
  "204.3833",
  13,
  6,
  "Pb",
  "Lead",
  "207.2",
  14,
  6,
  "Bi",
  "Bismuth",
  "208.9804",
  15,
  6,
  "Po",
  "Polonium",
  "(209)",
  16,
  6,
  "At",
  "Astatine",
  "(210)",
  17,
  6,
  "Rn",
  "Radon",
  "(222)",
  18,
  6,
  "Fr",
  "Francium",
  "(223)",
  1,
  7,
  "Ra",
  "Radium",
  "(226)",
  2,
  7,
  "Ac",
  "Actinium",
  "(227)",
  4,
  10,
  "Th",
  "Thorium",
  "232.03806",
  5,
  10,
  "Pa",
  "Protactinium",
  "231.0588",
  6,
  10,
  "U",
  "Uranium",
  "238.02891",
  7,
  10,
  "Np",
  "Neptunium",
  "(237)",
  8,
  10,
  "Pu",
  "Plutonium",
  "(244)",
  9,
  10,
  "Am",
  "Americium",
  "(243)",
  10,
  10,
  "Cm",
  "Curium",
  "(247)",
  11,
  10,
  "Bk",
  "Berkelium",
  "(247)",
  12,
  10,
  "Cf",
  "Californium",
  "(251)",
  13,
  10,
  "Es",
  "Einstenium",
  "(252)",
  14,
  10,
  "Fm",
  "Fermium",
  "(257)",
  15,
  10,
  "Md",
  "Mendelevium",
  "(258)",
  16,
  10,
  "No",
  "Nobelium",
  "(259)",
  17,
  10,
  "Lr",
  "Lawrencium",
  "(262)",
  18,
  10,
  "Rf",
  "Rutherfordium",
  "(267)",
  4,
  7,
  "Db",
  "Dubnium",
  "(268)",
  5,
  7,
  "Sg",
  "Seaborgium",
  "(271)",
  6,
  7,
  "Bh",
  "Bohrium",
  "(272)",
  7,
  7,
  "Hs",
  "Hassium",
  "(270)",
  8,
  7,
  "Mt",
  "Meitnerium",
  "(276)",
  9,
  7,
  "Ds",
  "Darmstadium",
  "(281)",
  10,
  7,
  "Rg",
  "Roentgenium",
  "(280)",
  11,
  7,
  "Cn",
  "Copernicium",
  "(285)",
  12,
  7,
  "Uut",
  "Unutrium",
  "(284)",
  13,
  7,
  "Fl",
  "Flerovium",
  "(289)",
  14,
  7,
  "Uup",
  "Ununpentium",
  "(288)",
  15,
  7,
  "Lv",
  "Livermorium",
  "(293)",
  16,
  7,
  "Uus",
  "Ununseptium",
  "(294)",
  17,
  7,
  "Uuo",
  "Ununoctium",
  "(294)",
  18,
  7,
];

var camera, scene, renderer;
var controls;
var transformation = ref("helix");
var objects = [];
var targets = { table: [], sphere: [], helix: [], grid: [] };
let recognition = null;

onMounted(() => {
  if ("webkitSpeechRecognition" in window) {
    recognition = new webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.lang = "cmn-Hans-CN";
    console.log(recognition);
    recognition.onaudiostart = function () {
      // speech.value = '开始录音';

      console.log("开始录音");
    };

    recognition.ononmatch = function () {
      console.log("没有匹配结果，请再次尝试");
      // recognition.start();
    };

    recognition.onerror = function (event) {
      console.log("错误，请再次尝试", event);
      //   recognition.stop();

      //   setTimeout(() => {
      // recognition.start();

      //   }, 5000);
    };

    recognition.onend = function () {
      console.log("识别结束");
      recognition.stop();
      setTimeout(() => {
        recognition.start();
      }, 5000);
    };
    // 如果得到与语音匹配的值，则会触发result事件。

    // onsoundstart
    recognition.onresult = function (event) {
      console.log("语音输入结束，正在识别");
      if (event.results.length > 0) {
        var length = event.results.length;
        var results = event.results[length - 1];
        // console.log(results);
        var topResult = results[0];
        const str = topResult.transcript;
        if (str.includes("变")) {
          console.log("变形", str);
          if (str.includes("平面") || str.includes("一") || str.includes("1")) {
            transform(targets.table, 1000);
            transformation.value = "table";
          } else if (
            str.includes("球") ||
            str.includes("2") ||
            str.includes("二")
          ) {
            transform(targets.sphere, 1000);
            transformation.value = "sphere";
          } else if (
            str.includes("环") ||
            str.includes("3") ||
            str.includes("三")
          ) {
            transform(targets.helix, 1000);
            transformation.value = "helix";
          } else if (
            str.includes("排") ||
            str.includes("4") ||
            str.includes("四") ||
            str.includes("列")
          ) {
            transform(targets.grid, 1000);
            transformation.value = "grid";
          }
        }
        // recognition.start();
      }
    };
  }
  init();
  animate();
  // mediapipe初始化
  mediaCtx = internalInstance.proxy.$refs.output_canvas.getContext("2d");
  initMedia();
  console.log("window:", window);
});

function init() {
  camera = new THREE.PerspectiveCamera(
    40,
    window.innerWidth / window.innerHeight,
    1,
    10000
  );
  camera.position.z = 4000;
  console.log("camera.position", camera.position);
  scene = new THREE.Scene();

  // table

  for (var i = 0; i < table.length; i += 5) {
    var element = document.createElement("div");
    element.className = "element";
    element.style.backgroundColor =
      "rgba(0,127,127," + (Math.random() * 0.5 + 0.25) + ")";

    var number = document.createElement("div");
    number.className = "number";
    number.textContent = i / 5 + 1;
    element.appendChild(number);

    var symbol = document.createElement("div");
    symbol.className = "symbol";
    symbol.textContent = table[i];
    element.appendChild(symbol);

    var details = document.createElement("div");
    details.className = "details";
    details.innerHTML = table[i + 1] + "<br>" + table[i + 2];
    element.appendChild(details);

    var object = new THREE.CSS3DObject(element);
    object.position.x = Math.random() * 4000 - 2000;
    object.position.y = Math.random() * 4000 - 2000;
    object.position.z = Math.random() * 4000 - 2000;
    scene.add(object);

    objects.push(object);

    //

    var object = new THREE.Object3D();
    object.position.x = table[i + 3] * 140 - 1330;
    object.position.y = -(table[i + 4] * 180) + 990;

    targets.table.push(object);
  }

  // sphere

  var vector = new THREE.Vector3();

  for (var i = 0, l = objects.length; i < l; i++) {
    var phi = Math.acos(-1 + (2 * i) / l);
    var theta = Math.sqrt(l * Math.PI) * phi;

    var object = new THREE.Object3D();

    object.position.x = 800 * Math.cos(theta) * Math.sin(phi);
    object.position.y = 800 * Math.sin(theta) * Math.sin(phi);
    object.position.z = 800 * Math.cos(phi);

    vector.copy(object.position).multiplyScalar(2);

    object.lookAt(vector);

    targets.sphere.push(object);
  }

  // helix

  var vector = new THREE.Vector3();

  for (var i = 0, l = objects.length; i < l; i++) {
    var phi = i * 0.175 + Math.PI;

    var object = new THREE.Object3D();

    object.position.x = 900 * Math.sin(phi);
    object.position.y = -(i * 8) + 450;
    object.position.z = 900 * Math.cos(phi);

    vector.x = object.position.x * 2;
    vector.y = object.position.y;
    vector.z = object.position.z * 2;

    object.lookAt(vector);

    targets.helix.push(object);
  }

  // grid

  for (var i = 0; i < objects.length; i++) {
    var object = new THREE.Object3D();

    object.position.x = (i % 5) * 400 - 800;
    object.position.y = -(Math.floor(i / 5) % 5) * 400 + 800;
    object.position.z = Math.floor(i / 25) * 1000 - 2000;

    targets.grid.push(object);
  }

  //

  renderer = new THREE.CSS3DRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.domElement.style.position = "absolute";
  document.getElementById("container").appendChild(renderer.domElement);

  //

  controls = new THREE.TrackballControls(camera, renderer.domElement);
  controls.rotateSpeed = 0.5;
  controls.minDistance = 500;
  controls.maxDistance = 6000;
  controls.addEventListener("change", render);

  // transform(targets.table, 5000);
  transform(targets.helix, 5000);
  recognition.start();
  window.addEventListener("resize", onWindowResize, false);
}

function transform(targets, duration) {
  TWEEN.removeAll();

  for (var i = 0; i < objects.length; i++) {
    var object = objects[i];
    var target = targets[i];

    new TWEEN.Tween(object.position)
      .to(
        {
          x: target.position.x,
          y: target.position.y,
          z: target.position.z,
        },
        Math.random() * duration + duration
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(object.rotation)
      .to(
        {
          x: target.rotation.x,
          y: target.rotation.y,
          z: target.rotation.z,
        },
        Math.random() * duration + duration
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  }

  new TWEEN.Tween(this)
    .to({}, duration * 2)
    .onUpdate(render)
    .start();
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);

  render();
}

function animate() {
  requestAnimationFrame(animate);

  TWEEN.update();

  controls.update();
}

function render() {
  // console.log("render",scene, camera)
  renderer.render(scene, camera);
}

// mediapipe,手势控制
var mediaCtx = null;
var canvasCtx;
var mediaWidth = ref(null);
var mediaHeight = ref(null);
var handPosition = null;
var tableTransform = 0;
var sphereTransform = 0;
var helixTransform = 0;
var gridTransform = 0;
var lastTransform = new Date().getTime();
var resetTime = 0;

function throttle(fn, time) {
  let timer = null;
  time = time || 1000;
  return function (...args) {
    if (timer) {
      return;
    }
    const _this = this;
    timer = setTimeout(() => {
      timer = null;
    }, time);
    fn.apply(_this, args);
  };
}
function initMedia() {
  const hands = new Hands({
    locateFile: (file) => {
      // return `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`;
      return `model/${file}`;
    },
  });
  console.log("hands", hands);
  hands.setOptions({
    selfieMode: true,
    maxNumHands: 2,
    modelComplexity: 1,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5,
  });

  hands.onResults(onResults);

  const camera = new Camera(internalInstance.proxy.$refs.input_video, {
    onFrame: async () => {
      await hands.send({ image: internalInstance.proxy.$refs.input_video });
    },
  });
  camera.start();
}
function onResults(results) {
  mediaWidth.value = results.image.width;
  mediaHeight.value = results.image.height;
  mediaCtx.save();
  mediaCtx.clearRect(0, 0, results.image.width, results.image.height);
  mediaCtx.drawImage(
    results.image,
    0,
    0,
    results.image.width,
    results.image.height
  );
  findHands(results);
  mediaCtx.restore();
}
function findHands(results, draw = true) {
  if (results.multiHandLandmarks) {
    throttle(
      changeCameraPosition(results.multiHandLandmarks, handPosition),
      400
    );
    for (const landmarks of results.multiHandLandmarks) {
      drawConnectors(mediaCtx, landmarks, HAND_CONNECTIONS, {
        color: "#00FF00",
        lineWidth: 5,
      });
      if (draw) {
        drawLandmarks(mediaCtx, landmarks, {
          color: "#FF0000",
          lineWidth: 2,
        });
      }
    }
  }
}
function changeCameraPosition(thisHand, lastestHandString) {
  const thisTime = new Date().getTime();
  let proportion = 1;
  // 如果上一帧有手，这一帧没手，认为不存在互动,开启重置计时器
  if (lastestHandString && thisHand?.length == 0) {
    resetTime = new Date().getTime();
  }
  // 计时器达到10秒，重置画面
  if (resetTime && thisTime - resetTime > 10000) {
    // transform(targets.table, 2000);
    transform(targets.helix, 2000);
    transformation.value = "helix";
    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = 4000;
    resetTime = 0;
    // recognition.stop();
    // setTimeout(() => {
    //   recognition.start();
    // }, 5000);
  }
  if (thisHand && Array.isArray(thisHand) && thisHand.length > 0) {
    const effectiveHands = thisHand.filter((item) => {
      let distance0to4 = 0.1;
      if ((item && item[12] && item[0] && item[12].x && item[0].x && item[12].y && item[0].y)) {
        distance0to4 = Math.abs(
          Math.sqrt(
            Math.pow(item[12].x - item[0].x, 2) +
              Math.pow(item[12].y - item[0].y, 2)
          )
        );
      }

      // console.log(distance0to4);
      if (item[4].y <= item[0].y && distance0to4 >= 0.1) {
        proportion = distance0to4.toFixed(2) * 10;
        return true;
      }
    });
    // 如果识别到手，但不是有效手，且未开启重置计时器时，开启计时
    if (!resetTime && effectiveHands.length == 0 && lastestHandString) {
      resetTime = new Date().getTime();
    }
    if (lastestHandString && effectiveHands.length > 0) {
      const thisThumb = effectiveHands[0][4]; // 这一帧手的大拇指位置
      const lastestHand = JSON.parse(lastestHandString); // 上一帧手的位置
      const lastestThumb = lastestHand[0][4];

      let distance = Math.abs(
        Math.sqrt(
          Math.pow(effectiveHands[0][4].x - effectiveHands[0][8].x, 2) +
            Math.pow(effectiveHands[0][4].y - effectiveHands[0][8].y, 2)
        )
      );
      // 当只有一只手，且上一帧也只有一只手时；一个手啮合，走拖动程序
      if (
        distance < 0.08 &&
        effectiveHands.length == 1 &&
        lastestHand.length < 2
      ) {
        // 一个手指啮合
        // 左右旋转
        const xChange = lastestThumb.x - thisThumb.x;
        camera.position.x += xChange * 3500;
        if (camera.position.x <= -2500) {
          camera.position.x = -2500;
        }
        if (camera.position.x >= 2500) {
          camera.position.x = 2500;
        }
        // 摄像头垂直滚动
        const yChange = lastestThumb.y - thisThumb.y;
        // camera.position.y += yChange * -8000 / proportion;
        camera.position.y += yChange * -4000;
        if (camera.position.y <= -2500) {
          camera.position.y = -2500;
        }
        if (camera.position.y >= 2500) {
          camera.position.y = 2500;
        }
      }
      // 当只有一只手,且上一帧也为一只手的情况下，走变形程序
      if (effectiveHands.length == 1 && lastestHand.length < 2) {
        if (effectiveHands[0][8].x < 0.25 && effectiveHands[0][8].y < 0.08) {
          // 调用第一形态
          if (tableTransform) {
            if (
              thisTime - tableTransform > 1000 &&
              thisTime - lastTransform > 5000
            ) {
              transform(targets.table, 2000);
              transformation.value = "table";
              tableTransform = 0;
              sphereTransform = 0;
              helixTransform = 0;
              gridTransform = 0;
              lastTransform = new Date().getTime();
            }
          } else {
            tableTransform = new Date().getTime();
          }
        }
        if (
          effectiveHands[0][8].x < 0.5 &&
          effectiveHands[0][8].x > 0.25 &&
          effectiveHands[0][8].y < 0.08
        ) {
          // 调用第二形态
          if (sphereTransform) {
            const thisTime = new Date().getTime();
            if (
              thisTime - sphereTransform > 1000 &&
              thisTime - lastTransform > 5000
            ) {
              transform(targets.sphere, 2000);
              transformation.value = "sphere";
              tableTransform = 0;
              sphereTransform = 0;
              helixTransform = 0;
              gridTransform = 0;
              lastTransform = new Date().getTime();
            }
          } else {
            sphereTransform = new Date().getTime();
          }
        }
        if (
          effectiveHands[0][8].x < 0.75 &&
          effectiveHands[0][8].x > 0.5 &&
          effectiveHands[0][8].y < 0.08
        ) {
          // 调用第三形态
          if (helixTransform) {
            const thisTime = new Date().getTime();
            if (
              thisTime - helixTransform > 1000 &&
              thisTime - lastTransform > 5000
            ) {
              transformation.value = "helix";
              transform(targets.helix, 2000);
              tableTransform = 0;
              sphereTransform = 0;
              helixTransform = 0;
              gridTransform = 0;
              lastTransform = new Date().getTime();
            }
          } else {
            helixTransform = new Date().getTime();
          }
        }
        if (
          effectiveHands[0][8].x < 1 &&
          effectiveHands[0][8].x > 0.75 &&
          effectiveHands[0][8].y < 0.08
        ) {
          // 调用第四形态
          if (gridTransform) {
            const thisTime = new Date().getTime();
            if (
              thisTime - gridTransform > 1000 &&
              thisTime - lastTransform > 5000
            ) {
              transformation.value = "grid";
              // console.log(transformation)
              transform(targets.grid, 2000);
              tableTransform = 0;
              sphereTransform = 0;
              helixTransform = 0;
              gridTransform = 0;
              lastTransform = new Date().getTime();
            }
          } else {
            gridTransform = new Date().getTime();
          }
        }
      }
      if (effectiveHands.length > 1 && lastestHand.length > 1) {
        // 有两个手
        const thisAnotherHandInfo = effectiveHands[1]; // 这一帧手的位置
        const anotherDistance = Math.abs(
          Math.sqrt(
            Math.pow(thisAnotherHandInfo[4].x - thisAnotherHandInfo[8].x, 2) +
              Math.pow(thisAnotherHandInfo[4].y - thisAnotherHandInfo[8].y, 2)
          )
        );
        if (anotherDistance < 0.08 && distance < 0.08) {
          let twoDistance = Math.abs(
            Math.sqrt(
              Math.pow(effectiveHands[0][9].x - thisAnotherHandInfo[9].x, 2) +
                Math.pow(effectiveHands[0][9].y - thisAnotherHandInfo[9].y, 2)
            )
          );
          let lastTwoDistance = Math.abs(
            Math.sqrt(
              Math.pow(lastestHand[0][9].x - lastestHand[1][9].x, 2) +
                Math.pow(lastestHand[0][9].y - lastestHand[1][9].y, 2)
            )
          );
          // 摄像头远近
          // const zChange = lastestThumb.z - thisThumb.z;
          camera.position.z +=
            ((twoDistance - lastTwoDistance) * -6000) / proportion;
          if (camera.position.z <= 500) {
            camera.position.z = 500;
          }
          if (camera.position.z >= 5000) {
            camera.position.z = 5000;
          }
        }
      }
    }
    // 存在有效手时，记录当前手部位置
    if (effectiveHands.length > 0) {
      handPosition = JSON.stringify(effectiveHands);
      // 存在有效手在互动，重置计时器
      resetTime = 0;
    } else {
      // 不存在有效手，归零手部位置
      handPosition = null;
    }
  } else {
    handPosition = null;
  }
}
</script>
<style lang="scss">
html,
body {
  height: 100%;
}

body {
  background-color: #000000;
  margin: 0;
  font-family: Helvetica, sans-serif;
  overflow: hidden;
}

a {
  color: #ffffff;
}

#info {
  position: absolute;
  width: 100%;
  color: #ffffff;
  padding: 5px;
  font-family: Monospace;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  z-index: 1000;
}

#menu {
  position: absolute;
  width: 100%;
  top: 40px;
  text-align: center;
  z-index: 1000;
  left: 0;
}

.element {
  width: 120px;
  height: 160px;
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
  border: 1px solid rgba(127, 255, 255, 0.25);
  text-align: center;
  cursor: default;
}

.element:hover {
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
  border: 1px solid rgba(127, 255, 255, 0.75);
}

.element .number {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: rgba(127, 255, 255, 0.75);
}

.element .symbol {
  position: absolute;
  top: 40px;
  left: 0px;
  right: 0px;
  font-size: 60px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
}

.element .details {
  position: absolute;
  bottom: 15px;
  left: 0px;
  right: 0px;
  font-size: 12px;
  color: rgba(127, 255, 255, 0.75);
}

button {
  color: rgba(127, 255, 255, 0.75);
  background: transparent;
  outline: 1px solid rgba(127, 255, 255, 0.75);
  border: 0px;
  padding: 5px 10px;
  cursor: pointer;

  &.choose {
    color: #fff !important;
    background-color: rgba(0, 255, 255, 0.5) !important;
  }
}
button:hover {
  background-color: rgba(0, 255, 255, 0.5);
}
button:active {
  color: #000000;
  background-color: rgba(0, 255, 255, 0.75);
}
#app {
  position: relative;
}
.canvas-wrap {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 280px;
  border-radius: 12px;
  z-index: 1000;
  background-color: #ffffff40;
}
.vedio-wrap {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 0px;
  height: 0px;
  border-radius: 12px;
  z-index: 1000;
  background-color: #ffffff40;
}
.output_canvas {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 16px 8px;
}

.transformation {
  background-color: rgba(0, 255, 255, 0.5);
  color: #ffffff;
  background: transparent;
  outline: 1px solid rgba(127, 255, 255, 0.75);
  border: 0px;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
