<!--
 * @Author: ZXP
 * @LastEditors: ZXP
 * @Date: 2023-03-17 10:26:18
 * @LastEditTime: 2023-03-17 10:29:18
 * @Email: 895357083@qq.com
 * @FilePath: /media-demo-2/src/App.vue
 * @Environment: Win 10
 * @Description: 
-->
<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
